import React from "react"

import { GatsbyProps } from "Src/global-types"

import StandardLayout from "Components/core/StandardLayout"
import SEO from "Components/core/SEO"

const A11yResources: React.FC<GatsbyProps> = ({ location }) => {
  return (
    <StandardLayout location={location}>
      <SEO title="A11y Resources" />
      <h2>A11y Resources</h2>I started to collect useful links for my
      accessibility work. Check out the repo and feel free to create a PR if you
      think there is anything to ad!{" "}
      <a href="https://github.com/LiamMyles/A11y-Resources">
        view the a11y resources on github
      </a>
    </StandardLayout>
  )
}

export default A11yResources
